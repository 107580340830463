import { Box, Container, Link, Stack, Text } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/color-mode';

import { PageGeneral } from '@/modules/home/homeEntity';

const currentYear = new Date().getFullYear();

function PageFooter({ data }: { data: PageGeneral }) {
  return (
    <Box
      // Uncomment below if you want to use dynamic light/dark mode
      // bg={useColorModeValue('gray.50', 'gray.900')}
      // color={useColorModeValue('gray.700', 'gray.200')}
      
      // Hardcoded to dark mode
      bg={'gray.900'}
      color={'gray.200'}
    >
      <Container
        as={Stack}
        maxW={'7xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={'center'}
        align={'center'}
      >
        <Text>© Ruangguru Engineering {currentYear}. All rights reserved</Text>
      </Container>
    </Box>
  );
}

export { PageFooter };
