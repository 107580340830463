import Head from 'next/head';

import { images } from '@/shared/assets';

type HomeHeadProps = {
  title: string;
  description: string;
};

function HomeHead({ title, description }: HomeHeadProps) {
  return (
    <Head>
      <title>{title}</title>

      <meta key="title" name="title" content={title} />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="twitter:title" property="twitter:title" content={title} />
      <meta key="description" name="description" content={description} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={description}
      />
      <meta key="og:image" property="og:image" content={images('rea_logo')} />
      <meta
        key="twitter:image"
        property="twitter:image"
        content={images('rea_logo')}
      />
    </Head>
  );
}

export { HomeHead };
